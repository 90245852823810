/**
 * Do not edit directly
 * Generated on Wed, 02 Apr 2025 06:41:31 GMT
 */

:root {
  --condo-global-spacing-4: 4px;
  --condo-global-spacing-8: 8px;
  --condo-global-spacing-12: 12px;
  --condo-global-spacing-16: 16px;
  --condo-global-spacing-20: 20px;
  --condo-global-spacing-24: 24px;
  --condo-global-spacing-32: 32px;
  --condo-global-spacing-40: 40px;
  --condo-global-spacing-52: 52px;
  --condo-global-spacing-60: 60px;
  --condo-global-border-width-default: 1px;
  --condo-global-font-family-default: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-border-radius-small: 4px;
  --condo-global-border-radius-medium: 8px;
  --condo-global-border-radius-large: 12px;
  --condo-global-font-weight-regular: 400;
  --condo-global-font-weight-semibold: 600;
  --condo-global-font-weight-bold: 700;
  --condo-global-line-height-h1: 46px;
  --condo-global-line-height-h2: 32px;
  --condo-global-line-height-h3: 28px;
  --condo-global-line-height-h4: 24px;
  --condo-global-line-height-h5: 22px;
  --condo-global-line-height-h6: 20px;
  --condo-global-line-height-text-l: 24px;
  --condo-global-line-height-text-m: 22px;
  --condo-global-line-height-text-s: 20px;
  --condo-global-line-height-h1-sm: 36px;
  --condo-global-line-height-h4-sm: 26px;
  --condo-global-font-size-h1: 40px;
  --condo-global-font-size-h2: 26px;
  --condo-global-font-size-h3: 20px;
  --condo-global-font-size-h4: 16px;
  --condo-global-font-size-h5: 14px;
  --condo-global-font-size-h6: 12px;
  --condo-global-font-size-text-l: 16px;
  --condo-global-font-size-text-m: 14px;
  --condo-global-font-size-text-s: 12px;
  --condo-global-font-size-h1-sm: 30px;
  --condo-global-font-size-h4-sm: 18px;
  --condo-global-opacity-disabled: 50%;
  --condo-global-box-shadow-default: 0 4px 14px 0 #b2b9d966;
  --condo-global-box-shadow-active: 0 4px 14px 0 #4cd1754d;
  --condo-global-letter-spacing-default: -0.01em;
  --condo-global-letter-spacing-small: -0.03em;
  --condo-global-paragraph-spacing-h1: 40px;
  --condo-global-paragraph-spacing-h2: 24px;
  --condo-global-paragraph-spacing-h3: 20px;
  --condo-global-paragraph-spacing-h4: 16px;
  --condo-global-paragraph-spacing-h5: 14px;
  --condo-global-paragraph-spacing-h6: 12px;
  --condo-global-paragraph-spacing-text-l: 16px;
  --condo-global-paragraph-spacing-text-m: 14px;
  --condo-global-paragraph-spacing-text-s: 12px;
  --condo-global-typography-h1-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h1-font-weight: 700;
  --condo-global-typography-h1-line-height: 46px;
  --condo-global-typography-h1-font-size: 40px;
  --condo-global-typography-h1-letter-spacing: -0.03em;
  --condo-global-typography-h1-paragraph-spacing: 40px;
  --condo-global-typography-h2-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h2-font-weight: 700;
  --condo-global-typography-h2-line-height: 32px;
  --condo-global-typography-h2-font-size: 26px;
  --condo-global-typography-h2-letter-spacing: -0.03em;
  --condo-global-typography-h2-paragraph-spacing: 24px;
  --condo-global-typography-h3-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h3-font-weight: 600;
  --condo-global-typography-h3-line-height: 28px;
  --condo-global-typography-h3-font-size: 20px;
  --condo-global-typography-h3-letter-spacing: -0.03em;
  --condo-global-typography-h3-paragraph-spacing: 20px;
  --condo-global-typography-h4-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h4-font-weight: 600;
  --condo-global-typography-h4-line-height: 24px;
  --condo-global-typography-h4-font-size: 16px;
  --condo-global-typography-h4-letter-spacing: -0.01em;
  --condo-global-typography-h4-paragraph-spacing: 16px;
  --condo-global-typography-h5-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h5-font-weight: 600;
  --condo-global-typography-h5-line-height: 22px;
  --condo-global-typography-h5-font-size: 14px;
  --condo-global-typography-h5-letter-spacing: -0.01em;
  --condo-global-typography-h5-paragraph-spacing: 14px;
  --condo-global-typography-h6-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-h6-font-weight: 600;
  --condo-global-typography-h6-line-height: 20px;
  --condo-global-typography-h6-font-size: 12px;
  --condo-global-typography-h6-letter-spacing: -0.01em;
  --condo-global-typography-h6-paragraph-spacing: 12px;
  --condo-global-typography-text-l-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-text-l-font-weight: 400;
  --condo-global-typography-text-l-line-height: 24px;
  --condo-global-typography-text-l-font-size: 16px;
  --condo-global-typography-text-l-letter-spacing: -0.01em;
  --condo-global-typography-text-l-paragraph-spacing: 16px;
  --condo-global-typography-text-m-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-text-m-font-weight: 400;
  --condo-global-typography-text-m-line-height: 22px;
  --condo-global-typography-text-m-font-size: 14px;
  --condo-global-typography-text-m-letter-spacing: -0.01em;
  --condo-global-typography-text-m-paragraph-spacing: 14px;
  --condo-global-typography-text-s-font-family: "Wix Madefor Display", var(--condo-font-fallback);
  --condo-global-typography-text-s-font-weight: 400;
  --condo-global-typography-text-s-line-height: 20px;
  --condo-global-typography-text-s-font-size: 12px;
  --condo-global-typography-text-s-letter-spacing: -0.01em;
  --condo-global-typography-text-s-paragraph-spacing: 12px;
  --condo-global-color-white: #fff;
  --condo-global-color-black: #222;
  --condo-global-color-brand-gradient-1: linear-gradient(90deg, #e2ffeb 0%, #e7f4ff 100%);
  --condo-global-color-brand-gradient-3: linear-gradient(90deg, #85eba4 0%, #9fd5ff 100%);
  --condo-global-color-brand-gradient-5: linear-gradient(90deg, #26c756 0%, #4ba2e4 100%);
  --condo-global-color-brand-gradient-7: linear-gradient(90deg, #2abb56 0%, #3996dd 100%);
  --condo-global-color-green-1: #ebfaef;
  --condo-global-color-green-3: #88e2a3;
  --condo-global-color-green-5: #2bc359;
  --condo-global-color-green-7: #00b538;
  --condo-global-color-red-1: #ffeceb;
  --condo-global-color-red-3: #ff938e;
  --condo-global-color-red-5: #ff3b30;
  --condo-global-color-red-7: #ec0000;
  --condo-global-color-yellow-1: #fef8dd;
  --condo-global-color-yellow-3: #fcea99;
  --condo-global-color-yellow-5: #fadd56;
  --condo-global-color-yellow-7: #e9c000;
  --condo-global-color-orange-1: #fff5e6;
  --condo-global-color-orange-3: #ffbf66;
  --condo-global-color-orange-5: #ff9500;
  --condo-global-color-orange-7: #da7f00;
  --condo-global-color-blue-1: #e7f4ff;
  --condo-global-color-blue-3: #7dc0f8;
  --condo-global-color-blue-5: #2696f3;
  --condo-global-color-blue-7: #0b78d2;
  --condo-global-color-purple-1: #f2e9ff;
  --condo-global-color-purple-3: #9e7add;
  --condo-global-color-purple-5: #5e22c6;
  --condo-global-color-purple-7: #4b1c9e;
  --condo-global-color-pink-1: #ffeef3;
  --condo-global-color-pink-3: #f38aa7;
  --condo-global-color-pink-5: #eb3468;
  --condo-global-color-pink-7: #d21f52;
  --condo-global-color-brown-1: #f8f1e7;
  --condo-global-color-brown-3: #d2aa6e;
  --condo-global-color-brown-5: #b4710d;
  --condo-global-color-brown-7: #8d590c;
  --condo-global-color-teal-1: #e9f3f2;
  --condo-global-color-teal-3: #6db8b3;
  --condo-global-color-teal-5: #1c7e79;
  --condo-global-color-teal-7: #135754;
  --condo-global-color-cyan-1: #edfcf8;
  --condo-global-color-cyan-3: #a0e5d2;
  --condo-global-color-cyan-5: #5eceb0;
  --condo-global-color-cyan-7: #249677;
  --condo-global-color-gray-1: #f2f4f6;
  --condo-global-color-gray-3: #e1e5ed;
  --condo-global-color-gray-5: #d0d3e5;
  --condo-global-color-gray-7: #707695;
  --condo-global-transition-duration-default: 0.15s;
}
